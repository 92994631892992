import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Tooltip,
  Alert,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingIndicator } from "./CustomHooks";
import FileUploadComponent from "./FileUploadComponent";
import MarkdownRenderer from "./MarkdownRenderer";
import config from "../config";

function AssignmentEnhancer() {
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
  });
  
  const [formState, setFormState] = useState({
    assignmentText: "",
    selectedEnhancements: [],
    customRules: "",
    difficultyLevel: "medium",
    targetAudience: "",
  });

  const [analysisState, setAnalysisState] = useState({
    isAnalyzed: false,
    clarity: { status: "", opportunities: [], impact: "" },
    accessibility: { status: "", opportunities: [], impact: "" },
    assessment: { status: "", opportunities: [], impact: "" },
    engagement: { status: "", opportunities: [], impact: "" },
  });

  const [uiState, setUiState] = useState({
    loading: false,
    messages: [],
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });

  const [results, setResults] = useState({
    enhancedAssignment: null,
  });

  // Initialize WebSocket
  useEffect(() => {
    const wsInstance = new WebSocket(`${config.appUrl}/ws`);

    wsInstance.onopen = () => {
      setIsConnected(true);
      setUiState((prev) => ({
        ...prev,
        error: null,
      }));
    };

    wsInstance.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleWebSocketMessage(data);
    };

    wsInstance.onclose = () => {
      setIsConnected(false);
    };

    setWs(wsInstance);

    return () => {
      if (wsInstance) {
        wsInstance.close();
      }
    };
  }, []);

  const handleWebSocketMessage = (data) => {
    switch (data.state) {
      case "processing":
        setUiState((prev) => ({
          ...prev,
          loading: true,
          messages: [data.message],
        }));
        break;
      case "Success":
        setUiState((prev) => ({
          ...prev,
          loading: false,
        }));
        if (data.content) {
          if (data.type === "analysis") {
            setAnalysisState({
              isAnalyzed: true,
              ...data.content
            });
          } else if (data.type === "enhancement") {
            setResults({
              enhancedAssignment: data.content.enhanced_assignment,
            });
          }
        }
        break;
      case "error":
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: data.error,
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        break;
    }
  };

  const handleFileUpload = useCallback((file) => {
    setFileState({
      file,
      uploadStatus: "File selected",
    });
  }, []);

  const handleEnhancementSelect = (enhancement) => {
    setFormState((prev) => ({
      ...prev,
      selectedEnhancements: prev.selectedEnhancements.includes(enhancement)
        ? prev.selectedEnhancements.filter((e) => e !== enhancement)
        : [...prev.selectedEnhancements, enhancement],
    }));
  };

  const handleAnalyze = async () => {
    if (!ws || !isConnected) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "No connection available",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
      return;
    }

    setUiState((prev) => ({
      ...prev,
      loading: true,
      messages: ["Analyzing assignment..."],
    }));

    let contentToAnalyze = formState.assignmentText;

    if (fileState.file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const formData = {
          formType: "analyze_initial",
          fileContent: e.target.result,
          fileName: fileState.file.name,
          textContent: contentToAnalyze,
        };
        ws.send(JSON.stringify(formData));
      };
      reader.readAsDataURL(fileState.file);
    } else if (contentToAnalyze) {
      const formData = {
        formType: "analyze_initial",
        textContent: contentToAnalyze,
      };
      ws.send(JSON.stringify(formData));
    } else {
      setUiState((prev) => ({
        ...prev,
        loading: false,
        snackbarMessage: "Please provide an assignment to analyze",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  };

  const handleEnhance = async () => {
    if (!ws || !isConnected) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "No connection available",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
      return;
    }

    if (formState.selectedEnhancements.length === 0) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Please select at least one enhancement",
        snackbarSeverity: "warning",
        snackbarOpen: true,
      }));
      return;
    }

    setUiState((prev) => ({
      ...prev,
      loading: true,
      messages: ["Applying enhancements..."],
    }));

    const formData = {
      formType: "enhance_assignment",
      textContent: formState.assignmentText,
      selectedEnhancements: formState.selectedEnhancements,
      customRules: formState.customRules,
      difficultyLevel: formState.difficultyLevel,
      targetAudience: formState.targetAudience,
    };

    ws.send(JSON.stringify(formData));
  };

  const renderEnhancementOptions = () => {
    if (!analysisState.isAnalyzed) return null;

    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Available Enhancements
        </Typography>
        {Object.entries({
          udl_framework: "UDL Framework",
          clarity: "Clarity and Structure",
          accessibility: "Accessibility",
          assessment: "Assessment Design",
          engagement: "Student Engagement"
        }).map(([key, label]) => (
          <Accordion key={key}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" paragraph>
                Status: {analysisState[key].status}
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Impact: {analysisState[key].impact}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {analysisState[key].opportunities.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleEnhancementSelect(option)}
                    color={formState.selectedEnhancements.includes(option) ? "primary" : "default"}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Additional Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Target Audience"
                  placeholder="e.g., First-year undergraduate students"
                  value={formState.targetAudience}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      targetAudience: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Custom Rules or Requirements"
                  placeholder="Add any specific rules or requirements..."
                  value={formState.customRules}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      customRules: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleEnhance}
          disabled={formState.selectedEnhancements.length === 0}
          sx={{ mt: 2 }}
        >
          Apply Selected Enhancements
        </Button>
      </Paper>
    );
  };

  return (
    <Box sx={{ maxWidth: "90%", margin: "0 auto", pt: 6 }}>
      <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
        Improve an existing assignment - v0.1
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Upload Assignment
            </Typography>
            <FileUploadComponent
              onFileUpload={handleFileUpload}
              ws={ws}
              isConnected={isConnected}
              customDropzoneText="Upload your assignment file or paste text below"
            />
          </Paper> */}

          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Paste Assignment Text
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              placeholder="Paste your assignment text here..."
              value={formState.assignmentText}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  assignmentText: e.target.value,
                }))
              }
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAnalyze}
              disabled={uiState.loading || (!fileState.file && !formState.assignmentText)}
              sx={{ mt: 2 }}
            >
              Analyze Assignment
            </Button>
          </Paper>

          {renderEnhancementOptions()}
        </Grid>

        <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" component="div">
              To enhance your assignment, follow these steps:
            </Typography>
            <ul>
              <li>
                Paste your existing assignment text in the text area below
              </li>
              <li>
                Click "Analyze Assignment" to receive enhancement suggestions
              </li>
              <li>
                Select the suggested enhancements you'd like to apply from the available options
              </li>
              <li>
                Optionally, specify your target audience and any custom requirements
              </li>
              <li>
                Click "Apply Selected Enhancements" to generate an improved version of your assignment
              </li>
            </ul>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              The tool will analyze your assignment for clarity, accessibility, assessment design, and student engagement opportunities.
            </Typography>
          </Paper>
          {uiState.loading && (
            <LoadingIndicator messages={uiState.messages} />
          )}

          {results.enhancedAssignment && (
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Enhanced Assignment
              </Typography>
              <MarkdownRenderer content={results.enhancedAssignment} />
            </Paper>
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
          severity={uiState.snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AssignmentEnhancer; 