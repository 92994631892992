import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHead = ({ 
  title, 
  description, 
  keywords, 
  image,
  canonicalUrl,
  type = 'website',
  publishedAt,
  author = 'Assessment Partner'
}) => {
  const siteUrl = 'https://assessment-partner.com';
  const fullUrl = canonicalUrl || siteUrl;
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={fullUrl} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content={author} />
      
      {/* Open Graph */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || `${siteUrl}/logo.png`} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:site_name" content="Assessment Partner" />
      {publishedAt && <meta property="article:published_time" content={publishedAt} />}
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || `${siteUrl}/logo.png`} />
      
      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': type === 'article' ? 'Article' : 'WebSite',
          headline: title,
          description: description,
          image: image || `${siteUrl}/logo.png`,
          url: fullUrl,
          author: {
            '@type': 'Organization',
            name: author
          },
          publisher: {
            '@type': 'Organization',
            name: 'Assessment Partner',
            logo: {
              '@type': 'ImageObject',
              url: `${siteUrl}/logo.png`
            }
          },
          ...(publishedAt && { datePublished: publishedAt })
        })}
      </script>
    </Helmet>
  );
};

export default SEOHead;