export const seoConfig = {
  home: {
    title: 'Assessment Partner - AI-Powered Assessment Generation Platform',
    description: 'Generate high-quality assessments and MCQs using AI. Perfect for educators and training professionals.',
    keywords: 'assessment generator, AI assessment, MCQ generator, education technology, online assessment',
    type: 'website'
  },
  features: {
    title: 'Features - Assessment Partner',
    description: 'Explore our powerful features including AI-powered assessment generation, MCQ creation, and customization options.',
    keywords: 'assessment features, MCQ generation, AI assessment tools, customization',
    type: 'website'
  },
  team: {
    title: 'Our Team - Assessment Partner',
    description: 'Meet the expert team behind Assessment Partner, dedicated to revolutionizing assessment generation.',
    keywords: 'assessment experts, education technology team, AI specialists',
    type: 'website'
  },
  about: {
    title: 'About Us - Assessment Partner',
    description: 'Learn about our mission to transform assessment creation through AI technology.',
    keywords: 'about assessment partner, education technology company, AI assessment platform',
    type: 'website'
  },
  mcq: {
    title: 'MCQ Generator - Assessment Partner',
    description: 'Create professional multiple-choice questions instantly with our AI-powered MCQ generator.',
    keywords: 'MCQ generator, multiple choice questions, quiz maker, test generator',
    type: 'website'
  },
  assessment: {
    title: 'Assessment Generator - Assessment Partner',
    description: 'Generate comprehensive assessments tailored to your needs with our AI-powered platform.',
    keywords: 'assessment generator, test creator, exam maker, education tools',
    type: 'website'
  }
}; 