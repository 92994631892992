import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Tooltip,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "../config";
import MarkdownRenderer from "./MarkdownRenderer";
import { LoadingIndicator } from "./CustomHooks";
import FileUploadComponent from "./FileUploadComponent";

function FileUploadForm() {
  const navigate = useNavigate();
  const resultContentsRef = useRef(null);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectTimeoutRef = useRef(null);

  // Form data state
  const [formData, setFormData] = useState({
    userLevels: [],
    assessmentFormats: {},
    TypeOfAssessments: [],
    taxonomyModels: {},
  });

  // In FileUploadForm.js, add this with other state declarations
  const [analyzedData, setAnalyzedData] = useState({
    document_name: "",
    themes: [],
    subjects: [],
    concepts: [],
    theories: [],
    skills: [],
  });

  // Selected values state
  const [selectedValues, setSelectedValues] = useState({
    assessmentFormat: "",
    typeOfAssessment: "",
    taxonomyModel: "",
    skillField: "",
    userLevel: "",
    TopicField: "",
    genAiUse: "none",
    gptVersion: "gpt-4-1106-preview",
  });

  // File state
  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
  });

  // UI state
  const [uiState, setUiState] = useState({
    loading: false,
    messages: [],
    resultContents: "",
    visibleContentLength: 0,
    showSaveButton: false,
    saveButtonDisabled: false,
    token: null,
    showPrintButton: false,
    showCustomizeButton: false,
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setUiState((prev) => ({
          ...prev,
          messages: [data.message],
          queuePosition: data.queuePosition,
          loading: true,
          isQueued: true,
        }));
        break;

      case "processing":
        const statusMessage =
          data.message ||
          (data.status === "processing" && data.message) ||
          "Processing...";
        setUiState((prev) => ({
          ...prev,
          messages: [statusMessage],
          loading: true,
          isQueued: false,
        }));
        break;

      case "Success":
        if (
          data.content &&
          typeof data.content === "object" &&
          data.content.document_name
        ) {
          // Handle analyzed document data
          // console.log("DATA CONTENTS:", data.content);
          setAnalyzedData(data.content);
          setUiState((prev) => ({
            ...prev,
            loading: false,
            messages: [],
          }));
        } else {
          // Handle regular assessment content and set all UI states
          setUiState((prev) => ({
            ...prev,
            resultContents: data.content,
            loading: false,
            token: data.token,
            messages: [],
            showSaveButton: true,
            showPrintButton: true,
            showCustomizeButton: true,
          }));
        }
        break;

      case "done":
        setUiState((prev) => ({
          ...prev,
          showSaveButton: true,
          showPrintButton: true,
          showCustomizeButton: true,
          loading: false,
          messages: [],
        }));
        break;

      default:
        if (data.error) {
          console.error("Error:", data.error);
          setUiState((prev) => ({
            ...prev,
            snackbarMessage: "An error occurred",
            snackbarSeverity: "error",
            snackbarOpen: true,
            loading: false,
            messages: [],
          }));
        }
    }
  }, []);

  // Initialize WebSocket
  const initWebSocket = React.useCallback(() => {
    if (ws) {
      ws.close();
    }

    const wsInstance = new WebSocket(`${config.appUrl}/ws`);

    wsInstance.onopen = () => {
      setIsConnected(true);
      setUiState((prev) => ({
        ...prev,
        error: null,
        snackbarOpen: false,
      }));
      console.log("WebSocket connected");
    };

    wsInstance.onmessage = handleWebSocketMessage;

    wsInstance.onclose = (event) => {
      setIsConnected(false);
      console.log("WebSocket closed:", event);

      if (!event.wasClean) {
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Connection lost. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }

      if (!window.isUnloading) {
        reconnectTimeoutRef.current = setTimeout(() => {
          console.log("Attempting to reconnect...");
          initWebSocket();
        }, 3000);
      }
    };

    wsInstance.onerror = (error) => {
      console.error("WebSocket error:", error);
      setUiState((prev) => ({
        ...prev,
        loading: false,
        snackbarMessage: "Connection error. Please try again.",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    };

    setWs(wsInstance);
  }, [handleWebSocketMessage]);

  // Initialize WebSocket on component mount
  useEffect(() => {
    window.isUnloading = false;
    initWebSocket();

    const handleBeforeUnload = () => {
      window.isUnloading = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.isUnloading = true;
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (ws) {
        ws.close();
      }
    };
  }, [initWebSocket]);

  // Fetch form data
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();

      setFormData({
        userLevels: data.user_level,
        TypeOfAssessments: data.type_of_assessment,
        assessmentFormats: {
          Written: data.assessment_formats.Written,
          Oral: data.assessment_formats.Oral,
          Practical: data.assessment_formats.Practical,
          Performance: data.assessment_formats.Performance,
          "Problem based": data.assessment_formats.Problem_based,
        },
        taxonomyModels: {
          "Blooms Taxonomy": data.taxonomies.blooms_taxonomy,
          "Solo Taxonomy": data.taxonomies.solo_taxonomy,
          "Creativity Taxonomy": data.taxonomies.creativity_taxonomy,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Error fetching form data",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle chip clicks
  const handleChipClick = useCallback((value) => {
    setSelectedValues((prev) => ({
      ...prev,
      TopicField: value.name || value, // Handle both object and string cases
    }));
  }, []);

  const handleChipClickSkills = useCallback((value) => {
    setSelectedValues((prev) => ({
      ...prev,
      skillField: value.name || value, // Handle both object and string cases
    }));
  }, []);

  const AnalyzedDataChips = useMemo(() => {
    const allItems = [
      ...(analyzedData.themes || []),
      ...(analyzedData.subjects || []),
      ...(analyzedData.concepts || []),
      ...(analyzedData.theories || []),
    ].filter((item) => item && item.name);

    const SkillsItems = [...(analyzedData.skills || [])].filter(
      (item) => item && item.name
    );

    return (
      allItems.length > 0 && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Suggested Topics:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {allItems.map((item, index) => (
              <Tooltip
                key={index}
                title={item.details || item.detail || ""}
                arrow
              >
                <Chip
                  label={item.name}
                  onClick={() => handleChipClick(item.name)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                  variant="outlined"
                />
              </Tooltip>
            ))}
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2  }}>
            Suggested Skills:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {SkillsItems.map((item, index) => (
              <Tooltip
                key={index}
                title={item.details || item.detail || ""}
                arrow
              >
                <Chip
                  label={item.name}
                  onClick={() => handleChipClickSkills(item.name)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                  variant="outlined"
                />
              </Tooltip>
            ))}
          </Box>
        </Box>
      )
    );
  }, [analyzedData, handleChipClick, handleChipClickSkills]);

  // File upload handlers
  const handleFileUpload = useCallback((file) => {
    setFileState((prev) => ({
      ...prev,
      file: file,
      uploadStatus: "",
    }));
  }, []);

  const handleAnalysisComplete = useCallback((topicText) => {
    setSelectedValues((prev) => ({
      ...prev,
      TopicField: topicText,
    }));
    setUiState((prev) => ({
      ...prev,
      showSaveButton: false,
      showCustomizeButton: false,
      showPrintButton: false,
    }));
  }, []);

  // Form submission handler
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!fileState.file) {
        setFileState((prev) => ({
          ...prev,
          uploadStatus: "Please select a file to upload.",
        }));
        return;
      }

      if (!ws || !isConnected) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "No connection available. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        return;
      }

      setUiState((prev) => ({
        ...prev,
        loading: true,
        messages: ["Initializing file upload and assessment generation..."],
      }));

      const reader = new FileReader();

      reader.onload = async (e) => {
        const formDataToSend = {
          ...selectedValues,
          formType: "document_upload",
          fileContent: e.target.result,
          fileName: fileState.file.name,
        };

        try {
          ws.send(JSON.stringify(formDataToSend));
        } catch (error) {
          console.error("Error sending data:", error);
          setUiState((prev) => ({
            ...prev,
            loading: false,
            snackbarMessage: "Failed to send request. Please try again.",
            snackbarSeverity: "error",
            snackbarOpen: true,
          }));
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Error reading file. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      };

      reader.readAsDataURL(fileState.file);
    },
    [fileState.file, selectedValues, ws, isConnected]
  );

  // Save and share handler
  const handleSaveAndShare = useCallback(async () => {
    try {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: true }));

      const response = await fetch(`${config.webUrl}/assessments/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: uiState.token }),
      });

      if (!response.ok) throw new Error("Failed to save assessment");

      const data = await response.json();
      console.log("Assessment saved:", data);

      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Assessment saved successfully",
        snackbarSeverity: "success",
        snackbarOpen: true,
      }));
    } catch (error) {
      console.error("Error saving assessment:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to save assessment",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    } finally {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: false }));
    }
  }, [uiState.token]);

  // Customize handler
  const handleCustomize = useCallback(async () => {
    if (uiState.token) {
      try {
        await handleSaveAndShare();
        navigate(`/assessments/list/${uiState.token}`);
      } catch (error) {
        console.error("Error saving and navigating:", error);
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and customize. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    } else {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Please generate an assessment first.",
        snackbarSeverity: "warning",
        snackbarOpen: true,
      }));
    }
  }, [uiState.token, handleSaveAndShare, navigate]);

  // Handle print
  const handlePrint = useCallback(() => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      MarkdownRenderer.getPrintContent(uiState.resultContents)
    );
    printWindow.document.close();
    printWindow.print();
  }, [uiState.resultContents]);

  // Field change handler
  const handleFieldChange = useCallback(
    (field) => (event, newValue) => {
      setSelectedValues((prev) => ({
        ...prev,
        [field]: newValue?.optionValue ?? event.target.value,
      }));
    },
    []
  );

  return (
    <Box sx={{ maxWidth: "80%", margin: "0 auto" }}>
      
      <Typography variant="h4" component="h1" sx={{ mb: 4, pt: 6 }}>
        Assessments development with your uploaded data
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <FileUploadComponent
              onFileUpload={handleFileUpload}
              onAnalysisComplete={handleAnalysisComplete}
              ws={ws}
              isConnected={isConnected}
              analyzedData={analyzedData}
              setAnalyzedData={setAnalyzedData}
            />

            {/* User Level Selection */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Level of study</InputLabel>
              <Select
                value={selectedValues.userLevel}
                onChange={handleFieldChange("userLevel")}
                label="Level of study"
              >
                {formData.userLevels.map((item) => (
                  <MenuItem key={item.optionValue} value={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Assessment Format */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Assessment Format</InputLabel>
              <Select
                value={selectedValues.assessmentFormat}
                onChange={handleFieldChange("assessmentFormat")}
                label="Assessment Format"
              >
                {Object.entries(formData.assessmentFormats).map(
                  ([group, items]) => [
                    <MenuItem
                      key={group}
                      value={group}
                      disabled
                      sx={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        key={item.optionValue}
                        value={item.optionValue}
                        sx={{ pl: 4 }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ]
                )}
              </Select>
            </FormControl>

            {/* Topic Field */}
            <FormControl fullWidth margin="normal">
              <TextField
                label="Topic within the discipline"
                variant="outlined"
                value={selectedValues.TopicField}
                onChange={handleFieldChange("TopicField")}
                placeholder="e.g. Algebra, Geometry, etc."
              />
            </FormControl>

            {/* Skills Field */}
            <FormControl fullWidth margin="normal">
              <TextField
                label="Skills | Competencies | Specialization"
                variant="outlined"
                value={selectedValues.skillField}
                onChange={handleFieldChange("skillField")}
                placeholder="e.g. Data Analysis, Critical Thinking, etc."
              />
            </FormControl>

            {/* Type of Assessment */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Type of Assessment</InputLabel>
              <Select
                value={selectedValues.typeOfAssessment}
                onChange={handleFieldChange("typeOfAssessment")}
                label="Type of Assessment"
              >
                {formData.TypeOfAssessments.map((item) => (
                  <MenuItem key={item.optionValue} value={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Taxonomy Model */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Learning Taxonomy or Model</InputLabel>
              <Select
                value={selectedValues.taxonomyModel}
                onChange={handleFieldChange("taxonomyModel")}
                label="Learning Taxonomy or Model"
              >
                {Object.entries(formData.taxonomyModels).map(
                  ([group, items]) => [
                    <MenuItem
                      key={group}
                      value={group}
                      disabled
                      sx={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        key={item.optionValue}
                        value={item.optionValue}
                        sx={{ pl: 4 }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ]
                )}
              </Select>
            </FormControl>

            {/* GenAI Usage */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Generative AI Usage</InputLabel>
              <Select
                value={selectedValues.genAiUse}
                onChange={handleFieldChange("genAiUse")}
                label="Generative AI Usage"
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="usegenai">Incorporate Generative AI</MenuItem>
                <MenuItem value="deterai">
                  Mitigate use of Generative AI
                </MenuItem>
              </Select>
            </FormControl>

            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={uiState.loading || !fileState.file}
                fullWidth
              >
                Generate Assessment
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" component="div">
              To create a customized assessment based on your uploaded
              document, follow these steps:
            </Typography>
            <ul>
              <li>
                Upload a PDF, TXT, or DOCX file related to your course content
              </li>
              <li>
                Specify key topics and skills to contextualize the assessment
              </li>
              <li>Select a learning taxonomy and target level</li>
              <li>
                Choose between formative (process-focused) or summative
                (product-focused) assessment
              </li>
              <li>
                Decide whether to incorporate or mitigate the use of AI
                generative tools and press "Generate"
              </li>
            </ul>
            {/* </Typography> */}
          </Box>
          <Box sx={{ mb: 4, ml: 4 }}>
            {/* Analyzed Data Chips */}
            {analyzedData && AnalyzedDataChips}
          </Box>

          {uiState.loading && <LoadingIndicator messages={uiState.messages} />}
        </Grid>
      </Grid>

      {/* Results Section */}
      {(uiState.loading || uiState.resultContents) && (
        <Box sx={{ mt: 4 }}>
          <Box ref={resultContentsRef} mt={2} className="resultsBox">
            <MarkdownRenderer content={uiState.resultContents} />
          </Box>

          {/* Action Buttons */}
          {uiState.resultContents && uiState.resultContents.length > 100 && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <>
                {uiState.showSaveButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveAndShare}
                    disabled={uiState.saveButtonDisabled}
                  >
                    Save and Share
                  </Button>
                )}

                {uiState.showCustomizeButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCustomize}
                    disabled={!uiState.token}
                  >
                    Customize
                  </Button>
                )}

                {uiState.showPrintButton && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                )}
              </>
            </Box>
          )}
        </Box>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
          severity={uiState.snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FileUploadForm;
