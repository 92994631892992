import React from 'react';
import SimpleForm from './SimpleForm';
import InfoSection from './InfoSection';
import { Box, Typography } from "@mui/material";

function SimpleAssessmentGenerator() {
    return (
        <Box justifyContent="center" alignItems="center" sx={{ maxWidth: '80%', margin: '0 auto' }}>
            {/* <h1 className="text-3xl font-bold my-4">Assessment Partner</h1> */}
            <Typography variant="h4" component="h1" sx={{ mb: 4, pt: 6 }}>Simple assessment development</Typography>
            <SimpleForm />
            <InfoSection />
        </Box>
    );
}

export default SimpleAssessmentGenerator;
