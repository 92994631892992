import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Snackbar,
  Paper,
  Chip,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import MCQVerification from "./MCQVerification";
import config from "../config";
import FileUploadComponent from "./FileUploadComponent";
import { LoadingIndicator } from "./CustomHooks";

function MCQUploadMaster() {
  // File upload states
  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
  });

  // MCQ generation states
  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  const [numberOfAnswers, setNumberOfAnswers] = useState(4);
  const [difficulty, setDifficulty] = useState("medium");
  const [userLevels, setUserLevels] = useState([]);
  const [mainFields, setMainFields] = useState([]);
  const [selectedUserLevel, setSelectedUserLevel] = useState("");
  const [selectedMainField, setSelectedMainField] = useState("");
  const [topicField, setTopicField] = useState("");
  const [skillField, setSkillField] = useState("");
  const [taxonomyModels, setTaxonomyModels] = useState({});
  const [selectedTaxonomyModel, setSelectedTaxonomyModel] = useState("");
  const [modelInfo, setModelInfo] = useState("");

  // Analyzed data state
  const [analyzedData, setAnalyzedData] = useState({
    document_name: "",
    themes: [],
    subjects: [],
    concepts: [],
    theories: [],
    skills: [],
  });

  // UI states
  const [loading, setLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showPrintButton, setShowPrintButton] = useState(false);
  const [messages, setMessages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [fileId, setFileId] = useState(null);
  const [ws, setWs] = useState(null);

  const handleNumberOfAnswersChange = (event) => { // Add onChange handler
    setNumberOfAnswers(event.target.value);
  };

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setMessages([data.message]);
        setLoading(true);
        break;

      case "processing":
        const statusMessage = data.message || "Processing...";
        setMessages([statusMessage]);
        setLoading(true);
        break;

      case "Success":
        if (
          data.content &&
          typeof data.content === "object" &&
          data.content.document_name
        ) {
          setAnalyzedData(data.content);
          setLoading(false);
          setMessages([]);
        } else if (data.file_id) {
          setFileId(data.file_id);
          fetchMCQ(data.file_id);
          setLoading(false);
        }
        break;

      case "done":
        setShowSaveButton(true);
        setShowPrintButton(true);
        setLoading(false);
        setMessages([]);
        break;

      default:
        if (data.error) {
          console.error("Error:", data.error);
          setSnackbarMessage("An error occurred");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setLoading(false);
          setMessages([]);
        }
    }
  }, []);

  // Initialize WebSocket
  useEffect(() => {
    const WS = new WebSocket(`${config.appUrl}/ws`);
    WS.onmessage = handleWebSocketMessage;
    setWs(WS);

    return () => {
      if (WS && WS.readyState === WebSocket.OPEN) {
        WS.close();
      }
    };
  }, [handleWebSocketMessage]);

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();
      setUserLevels(data.user_level);
      setMainFields(data.main_field);
      setTaxonomyModels(data.taxonomies);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbarMessage("Error fetching data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const fetchMCQ = async (id) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}`);
      if (!response.ok) throw new Error("Failed to fetch MCQ data");
      const mcqData = await response.json();
      setQuestions(mcqData.questions);
    } catch (error) {
      console.error("Error fetching MCQ:", error);
      setSnackbarMessage("Error fetching MCQ data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Handle chip clicks
  const handleChipClick = useCallback((value) => {
    setTopicField(value.name || value);
  }, []);

  const handleChipClickSkills = useCallback((value) => {
    setSkillField(value.name || value);
  }, []);

  // File upload handlers
  const handleFileUpload = useCallback(
    (file) => {
      setFileState((prev) => ({
        ...prev,
        file: file,
        uploadStatus: "",
      }));

      // Send file for analysis
      if (ws && ws.readyState === WebSocket.OPEN) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const formData = {
            formType: "extract_insights",
            fileContent: e.target.result,
            fileName: file.name,
          };

          try {
            ws.send(JSON.stringify(formData));
          } catch (error) {
            console.error("Error sending file for analysis:", error);
            setSnackbarMessage("Failed to analyze file");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        };

        reader.readAsDataURL(file);
      }
    },
    [ws]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fileState.file) {
      setSnackbarMessage("Please select a file to upload");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setMessages(["Processing file and generating MCQs..."]);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const formData = {
        fileContent: e.target.result,
        fileName: fileState.file.name,
        numberOfQuestions,
        numberOfAnswers,
        difficulty,
        userLevel: selectedUserLevel,
        mainField: selectedMainField,
        topic: topicField,
        skillField,
        taxonomyModel: selectedTaxonomyModel,
        formType: "mcq_with_context",
      };

      if (ws && ws.readyState === WebSocket.OPEN) {
        console.log("Sending form data:", formData);
        ws.send(JSON.stringify(formData));
      } else {
        setSnackbarMessage("WebSocket connection not available");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setLoading(false);
      }
    };

    reader.readAsDataURL(fileState.file);
  };

  const AnalyzedDataChips = useMemo(() => {
    const allItems = [
      ...(analyzedData.themes || []),
      ...(analyzedData.subjects || []),
      ...(analyzedData.concepts || []),
      ...(analyzedData.theories || []),
    ].filter((item) => item && item.name);

    const SkillsItems = [...(analyzedData.skills || [])].filter(
      (item) => item && item.name
    );

    return (
      allItems.length > 0 && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Suggested Topics:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {allItems.map((item, index) => (
              <Tooltip
                key={index}
                title={item.details || item.detail || ""}
                arrow
              >
                <Chip
                  label={item.name}
                  onClick={() => handleChipClick(item.name)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                  variant="outlined"
                />
              </Tooltip>
            ))}
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            Suggested Skills:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {SkillsItems.map((item, index) => (
              <Tooltip
                key={index}
                title={item.details || item.detail || ""}
                arrow
              >
                <Chip
                  label={item.name}
                  onClick={() => handleChipClickSkills(item.name)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                  variant="outlined"
                />
              </Tooltip>
            ))}
          </Box>
        </Box>
      )
    );
  }, [analyzedData, handleChipClick, handleChipClickSkills]);

  const handleSaveAndShare = async () => {
    if (!fileId) {
      setSnackbarMessage("No MCQs to save");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${config.webUrl}/mcq/save-and-share/${fileId}`,
        {
          method: "POST",
        }
      );
      if (!response.ok) throw new Error("Failed to save MCQs");

      setSnackbarMessage("MCQs saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving MCQs:", error);
      setSnackbarMessage("Failed to save MCQs");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const verifyQuestion = async (index) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [index]: { status: "verifying" },
    }));
    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: questions[index].question,
          selected_answer:
            questions[index].options[questions[index].correctAnswer],
        }),
      });
      const data = await response.json();
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: data.result,
          explanation: data.explanation,
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: "re-verify",
          explanation:
            "An error occurred during verification. Please try again.",
        },
      }));
    }
  };

  const handleNumberOfQuestionsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumberOfQuestions(value);
    if (value > 10) {
      setModelInfo(
        "Generating more than 10 questions may take longer due to the verification and overlap checking process."
      );
    } else {
      setModelInfo("");
    }
  };

  const handlePrint = useCallback(() => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
            <html>
                <head>
                    <title>Print MCQs</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .question { margin-bottom: 20px; }
                        .options { margin-left: 20px; }
                        .reason { font-style: italic; color: #666; }
                    </style>
                </head>
                <body>
                    ${questions
                      .map(
                        (q, index) => `
                        <div class="question">
                            <h3>${index + 1}. ${q.question}</h3>
                            <div class="options">
                                ${q.options
                                  .map(
                                    (option, i) => `
                                    <p>${String.fromCharCode(
                                      65 + i
                                    )}. ${option}</p>
                                `
                                  )
                                  .join("")}
                            </div>
                            <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                              65 + q.correctAnswer
                            )}</p>
                            <p class="reason"><strong>Explanation:</strong> ${
                              q.reason
                            }</p>
                        </div>
                    `
                      )
                      .join("")}
                </body>
            </html>
        `);
    printWindow.document.close();
    printWindow.print();
  }, [questions]);

  const toggleAnswers = () => setShowAnswers(!showAnswers);

  const renderMessage = (content) => (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({ node, ...props }) => <Typography paragraph {...props} />,
        h3: ({ node, ...props }) => (
          <Typography variant="h6" gutterBottom {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ marginBottom: "10px" }} {...props} />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <Typography variant="h4" component="h1" sx={{ mb: 4, pt: 6 }}>MCQ Generation from Documents</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <FileUploadComponent
              onFileUpload={handleFileUpload}
              ws={ws}
              isConnected={!!ws && ws.readyState === WebSocket.OPEN}
              analyzedData={analyzedData}
              setAnalyzedData={setAnalyzedData}
            />

            {/* Form fields from MCQMaster */}
            <FormControl fullWidth margin="normal">
              <Tooltip
                title="Select a Discipline. This field is searchable."
                placement="right"
              >
                <Autocomplete
                  options={mainFields}
                  getOptionLabel={(option) => option.optionText}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a Discipline" />
                  )}
                  value={
                    mainFields.find(
                      (option) => option.optionValue === selectedMainField
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedMainField(newValue ? newValue.optionValue : "");
                  }}
                />
              </Tooltip>
            </FormControl>

            {/* Select User Level */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="user-level-label">Level of study</InputLabel>
              <Select
                labelId="user-level-label"
                id="userLevels"
                value={selectedUserLevel}
                label="Select User Level"
                onChange={(e) => setSelectedUserLevel(e.target.value)}
              >
                {userLevels.map((item) => (
                  <MenuItem value={item.optionValue} key={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* TopicField */}
            {selectedMainField !== "other" &&
              selectedMainField !== "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="Topic_field"
                    label="Topic within the discipline (optional)"
                    variant="outlined"
                    value={topicField}
                    placeholder="e.g. Algebra, Geometry, etc."
                    onChange={(e) => setTopicField(e.target.value)}
                  />
                </FormControl>
              )}

            {/* Conditionally Rendered skills */}
            {selectedMainField !== "other" &&
              selectedMainField === "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <Tooltip
                    title="Enter specific skills, competencies, or specializations related to the assessment (optional)"
                    placement="right"
                  >
                    <TextField
                      id="skills_field"
                      label="Skills | Competencies | Specialization (optional)"
                      variant="outlined"
                      value={skillField}
                      placeholder="e.g. Data Analysis, Critical Thinking, etc."
                      onChange={(e) => setSkillField(e.target.value)}
                    />
                  </Tooltip>
                </FormControl>
              )}

            {/* Learning Taxonomy or Model Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="taxonomy-model-label">
                Learning Taxonomy or Model
              </InputLabel>
              <Tooltip
                title="Educational taxonomies are frameworks used to classify learning objectives and outcomes across different levels of complexity, from basic knowledge acquisition to advanced critical thinking skills."
                placement="right"
              >
                <Select
                  labelId="taxonomy-model-label"
                  id="taxonomyModels"
                  value={selectedTaxonomyModel}
                  label="Learning Taxonomy or Model"
                  onChange={(e) => setSelectedTaxonomyModel(e.target.value)}
                >
                  {Object.entries(taxonomyModels).map(([group, items]) => [
                    <MenuItem
                      value={group}
                      key={group}
                      disabled
                      style={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        value={item.optionValue}
                        key={item.optionValue}
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </Tooltip>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Number of Questions</InputLabel>
              <Select
                value={numberOfQuestions}
                onChange={handleNumberOfQuestionsChange}
                label="Number of Questions"
                required
              >
                {[5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Number of answer options</InputLabel>
              <Select
                value={numberOfAnswers}
                onChange={handleNumberOfAnswersChange} // Attach onChange handler
                label="Number of answer options"
              >
                {[3, 4, 5, 6].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>level of difficulty</InputLabel>
              <Select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                label="level of difficulty"
                required
              >
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
            {modelInfo && (
              <Alert severity="info" style={{ marginTop: "10px" }}>
                {modelInfo}
              </Alert>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !fileState.file}
              sx={{ mt: 2 }}
            >
              Generate MCQs
            </Button>
          </form>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="body1">
              <Typography variant="h5">
                Upload your course materials or lecture notes to generate
                targeted multiple-choice questions:
              </Typography>
              <ul>
                <li>
                  Upload a PDF, TXT, or DOCX file related to your course
                  content.
                </li>
                <li>
                  The initial analysis will provide you with suggested topics.
                  Click and select your preferred topic(s).
                </li>
                <li>Select a learning taxonomy and target level.</li>
                <li>
                  Select the required number of questions and press "Generate."
                </li>
              </ul>
            </Typography>
          </div>
          {loading && <LoadingIndicator messages={messages} />}
          {/* Analyzed Data Chips */}
          <Box sx={{ mb: 4, ml: 4 }}>
            {/* Analyzed Data Chips */}
            {analyzedData && AnalyzedDataChips}
          </Box>
        </Grid>
      </Grid>

      {questions.length > 0 && (
        <Box mt={4}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleAnswers}
              startIcon={
                showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              sx={{ ml: 1 }}
            >
              {showAnswers ? "Hide Answers" : "Show Answers"}
            </Button>
          </Box>

          {questions.map((q, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Question {index + 1} of {questions.length}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {renderMessage(q.question)}
                </Typography>
                <RadioGroup className="q_radio_group">
                  {q.options.map((option, i) => (
                    <FormControlLabel
                      key={i}
                      value={String(i)}
                      control={<Radio />}
                      label={renderMessage(option)}
                      disabled
                    />
                  ))}
                </RadioGroup>
                {showAnswers && (
                  <>
                    <Typography variant="body1" color="primary" sx={{ mt: 1 }} className="mcq_answer">
                      Correct Answer:{" "}
                      {String.fromCharCode(65 + q.correctAnswer)}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      Explanation: {renderMessage(q.reason)}
                    </Typography>
                  </>
                )}
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <Tooltip title={verificationStatus[index]?.explanation || ""}>
                    <span>
                      <MCQVerification
                        status={verificationStatus[index]?.status}
                        onVerify={() => verifyQuestion(index)}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          ))}

          <Box mt={2} display="flex" gap={2}>
            {showSaveButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
              >
                Save and Share
              </Button>
            )}
            {showPrintButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MCQUploadMaster;
