import React from "react";
import { Box, Container, Grid, Typography, Link, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const footerLinks = {
    resources: [
      { name: "Contact Us", path: "/feedback" },
      { name: "Disclaimer", path: "/disclaimer" },
      { name: "Feedback", path: "/feedback" },
    ],
    company: [
      { name: "About Us", path: "/about" },
      { name: "Team", path: "/team" },
      { name: "How to Use", path: "/showcase" },
    ],
    legal: [
      { name: "Privacy Policy", path: "/privacy" },
      { name: "Terms of Use", path: "/terms" },
    ],
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: (theme) => theme.palette.mode === 'light' ? '#f8f8f8' : 'background.paper',
        py: 6,
        mt: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <img src="/logo.png" alt="Logo" width="120" />
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              AI-powered assessment creation platform for educators, developed by
              McMaster University and MacPherson Institute.
            </Typography>
          </Grid>

          {/* Links Sections */}
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" color="text.primary" gutterBottom>
                  Resources
                </Typography>
                {footerLinks.resources.map((link) => (
                  <Link
                    key={link.name}
                    href={link.path}
                    color="text.secondary"
                    display="block"
                    sx={{ mb: 1, textDecoration: 'none', '&:hover': { color: 'primary.main' } }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" color="text.primary" gutterBottom>
                  Company
                </Typography>
                {footerLinks.company.map((link) => (
                  <Link
                    key={link.name}
                    href={link.path}
                    color="text.secondary"
                    display="block"
                    sx={{ mb: 1, textDecoration: 'none', '&:hover': { color: 'primary.main' } }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" color="text.primary" gutterBottom>
                  Legal
                </Typography>
                {footerLinks.legal.map((link) => (
                  <Link
                    key={link.name}
                    href={link.path}
                    color="text.secondary"
                    display="block"
                    sx={{ mb: 1, textDecoration: 'none', '&:hover': { color: 'primary.main' } }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Assessment Partner | McMaster University
          </Typography>
          <Box>
            <Link
              href="https://mi.mcmaster.ca/"
              target="_blank"
              rel="noopener"
              sx={{ mr: 2 }}
            >
              <img 
                src="/macpherson-logo.png" 
                alt="MacPherson Institute" 
                height="30"
                style={{ 
                  opacity: 0.7,
                  transition: 'opacity 0.3s',
                  '&:hover': { opacity: 1 }
                }}
              />
            </Link>
            <Link
              href="https://mcmaster.ca"
              target="_blank"
              rel="noopener"
            >
              <img 
                src="/mcmaster_logo.png" 
                alt="McMaster University" 
                height="30"
                style={{ 
                  opacity: 0.7,
                  transition: 'opacity 0.3s',
                  '&:hover': { opacity: 1 }
                }}
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
